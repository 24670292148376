.aboutimg{
  border-radius: 20px;
  margin-bottom: 40px;
  box-shadow: 10px 10px 0px 0 rgb(249, 207, 242);
}

.lead{
  text-align: center;
  margin-bottom: 30px;
}

.lead_item{
  margin-bottom: 30px;
}

.name_history{
  border: 4px solid #F9CFF2;
  border-radius: 20px;
  background: #ffffff;
  padding: 20px;
}