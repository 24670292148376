.billing{
  background: #EDEBA0;
  /* margin-bottom: 50px; */
}

.billing_list{
  justify-content: space-between;
}

.billing_total{
  font-weight: bold;
  margin-bottom: 10px;
}

.billing_standard,
.billing_add{
  font-size: 14px;
}