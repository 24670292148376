/* src/Calendar.css */

.calendar {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-cell {
  /* border: 1px solid #ccc; */
  width: 18px;
  height: 23px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-cell.header {
    font-weight: bold;
    text-align: center;
    font-size: 8px;
}

.calendar-cell img {
  max-width: 100%;
  max-height: 100%;
}

.full-date-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.calendar-wrapper {
  /* display: flex; */
  overflow-x: auto;
  /* 必要に応じて追加スタイル */
}

/* プレビュー */
.preview{
  border: 1px solid #eee;
  padding: 10px;
  justify-content: center;
}

.flex.preview {
  /* displayを調整 */
  flex-shrink: 0;
  height: 202.7px;
  width: 300px;
  margin: 0 auto;
  /* その他のスタイル */
}

.month-image{
  width: 45%;
  margin-right: 15px;
}

.pre-image{
  width: 100%;
}

.pre-calendar{
  width: 50%;
}

.pre-right{
  font-size: 10px;
}

.pre-h2{
  margin-bottom: 5px;
}

.calendar_arrow {
  top: -130px;
}