.footer {
  background: #83CCD2;
  color: #ffffff;
  text-align: center;
  padding: 50px 0 10px;
}

.footer_logo{
  margin-bottom: 30px;
}

.share_list{
  justify-content: center;
  margin-bottom: 50px;
}

.share_item{
  margin-left: 10px;
}

.share_item:first-child{
  margin-left: 0;
}

.shareicon{
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  text-align: center;
  color: #83CCD2;
  font-weight: bold;
  line-height: 40px;
}

.sharename{
  position: relative;
  font-size: 10px;
  font-weight: bold;
  top: -6px;
}

.copyright{
  font-size: 12px;
}