.header{
  position: relative;
  padding: 10px;
  z-index: 1;
}

.logo {
  height: 25px;
  width: auto;
}

.link-button{
  position: relative;
  padding: 10px 30px 10px 50px;
  background: #83CCD2;
  border-radius: 60px 0 0 60px;
  color: #ffffff;
  font-weight: bold;
  font-size: 24px;
  font-family: "Dela Gothic One", sans-serif;
  top: 15px;
  right: -15px
}

.link-button::after{
  position: absolute;
  content: "";
  background-image: url(../../assets/deco01.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 65px;
  height: 45px;
  top: 0;
  left: -15px;
}

@media screen and (min-width: 769px){

}