.faq_list {
  color: #fff;
}

.faq_item {
  background: #83CCD2;
  border-radius: 20px;
  padding: 20px 20px 20px 70px;
  margin-bottom: 20px;
}

summary {
	list-style: none;
}

.question {
  position: relative;
  padding-bottom: 0;
}

.question::before{
  position: absolute;
  content: "Q.";
  font-size: 24px;
  color: #ffffff;
  left: -40px;
}

.answer {
  position: relative;
  display: block;
  padding-top: 20px;
  font-weight: bold;
}

.answer::before{
  position: absolute;
  content: "A.";
  font-size: 24px;
  color: #ffffff;
  left: -40px;
}