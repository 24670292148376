.month-grid {
  margin-bottom: 50px;
}

.up_lead {
  margin-bottom: 30px;
}

.trimming2 {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 20px;
  margin: 0 auto 30px;
}

.reactEasyCrop_Container {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 20px;
  margin: 0 auto 30px;
}

.trimming {
  position: relative;
  width: 250px;
  height: 250px;
  background: #DFDFDF;
  border-radius: 20px;
  margin: 0 auto 30px;
}

.trimming::after {
  position: absolute;
  content: "月を選択すると、\A写真を選択・トリミングできます";
  white-space: pre;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  bottom: 20px;
  left: 25px;
}

.numberimage {
  margin-bottom: 50px;
}

.number_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  margin-top: 30px;
}

.digit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid #423E3B;
  border-radius: 10px;
  height: 50px;
  font-weight: bold;
}

.upload-placeholder {
  position: absolute;
  background: #EDEBA0;
  color: #423E3B;
  font-weight: bold;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 20px;
  top: -10px;
  right: -10px;
}

.trimming02::after {
  content: "見本の写真入れる";
}

.imageupload-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  text-align: center;
}

.imageupload{
  display: block;
  position: relative;
  width: 300px;
  background: #83CCD2;
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0;
  border-radius: 50px;
}

.imageupload::after{
  position: absolute;
  display: inline-block;
  content: "";
  background-image: url(../assets/icon_album.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 65px;
  height: 45px;
  top: 0;
  right: -15px;
}

.album{
  margin-bottom: 10px;
}

.camera::after{
  background-image: url(../assets/icon_camera.svg);
}

.monthlist {
  background: #EDEBA0;
  border-radius: 10px;
}

.monthitem {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background-image: linear-gradient(to right, #423E3B 2px, transparent 2px), linear-gradient(to bottom, #423E3B 2px, transparent 2px), linear-gradient(to left, #423E3B 2px, transparent 2px), linear-gradient(to top, #423E3B 2px, transparent 2px);
  background-size: 7px 2px, 2px 7px, 7px 2px, 2px 7px;
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-position: left top, right 0px, 3px bottom, left 7px;
  border-radius: 10px;
  font-weight: bold;
}

/* 選択時 */
/* TODO: 選択時のスタイル当てる */
.monthitem-active {
  color: #83CCD2;
  background-image: linear-gradient(to right, #83CCD2 2px, transparent 2px), linear-gradient(to bottom, #83CCD2 2px, transparent 2px), linear-gradient(to left, #83CCD2 2px, transparent 2px), linear-gradient(to top, #83CCD2 2px, transparent 2px);
}

/* オプション */
.frame-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px 10px;
}

.frame-option {
  text-align: center;
}

.frame-thumbnail {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
  border: 1px solid #E3E2E2;
}

/* 選択時 */
/* TODO: 選択時のスタイル当てる */
.frame-thumbnail-active {
  border: 2px solid #83CCD2;
}

.frame-info {
  margin-top: 5px;
}

.option_item {
  margin-bottom: 20px;
}

.option_label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.option_label span {
  font-weight: normal;
}

.option_select {
  border: 2px solid #83CCD2;
  border-radius: 10px;
  width: 100%;
  padding: 8px 16px;
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets/arrow02.svg") no-repeat right 12px center / 18px auto;
}

.frame_name {
  font-weight: bold;
}

.frame_price {
  font-size: 14px;
}