.MainVisual{
  display: flex;
  position: relative;
  background-image: url(../../assets/mv.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 80px);
  top: -86px;
  justify-content: center;
  margin-bottom: -90px;
}

.scroll {
  padding-top: 60px;
  position: relative;
  text-align: center;
}
/* 矢印のアニメーション部分 */
.scroll::before {
  animation: scroll 2s infinite;
  border-bottom: 5px solid #83CCD2;
  border-left: 5px solid #83CCD2;
  content: "";
  height: 20px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  bottom: 50px;
  width: 20px;
}
/* 矢印のアニメーション */
@keyframes scroll {
  0% {
    opacity: 0;
    transform: rotate(-45deg) translate(0, 0);
  }
  40% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: rotate(-45deg) translate(-20px, 20px);
  }
  100% {
    opacity: 0;
  }
}

.mv_cvbtn{
  position: absolute;
  display: block;
  margin: auto;
  padding: 13px 40px;
  font-size: 24px;
  font-family: "Dela Gothic One", sans-serif;
  color: #ffffff;
  background: #83CCD2;
  border-radius: 60px;
  bottom: 50px;
}