.download{
  margin: 50px 0;
}

.download .lead{
  text-align: left;
}

.termlead{
  text-align: center;
  margin-bottom: 15px;
}

.card-element-container{
  padding: 20px 10px;
  border: 1px solid #EDEBA0;
  border-radius: 10px;
  margin-bottom: 20px;
  font-family: "M PLUS 1", sans-serif;
  color: #423E3B;
  height: 55px;
}

.ElementsApp{
  font-size: 16px;
  font-family: "M PLUS 1", sans-serif;
}

.download-mail{
  margin-bottom: 20px;
}

.download-mail-input{
  width: 100%;
  height: 55px;
  padding: 15px 10px;
  border: 1px solid #EDEBA0;
  border-radius: 10px;
  margin-top: 10px;
  font-family: "M PLUS 1", sans-serif;
  color: #423E3B;
}

::placeholder{
  opacity: 0.5;
}

.download-button{
  display: flex;
  position: relative;
  width: 300px;
  background: #EDEBA0;
  /* color: #ffffff; */
  font-weight: bold;
  font-size: 20px;
  padding: 15px 0;
  border-radius: 50px;
  justify-content: center;
  margin: 0 auto;
}


.error-message {
  text-align: center;
  color: #ff5a5f;
}