/* .splide__arrows,
.splide__arrows--ltr{
  display: none;
} */

.how2 {
  background: #83CCD2;
}

.how2 .h3 {
  color: #ffffff;
}

.how2list {
  overflow: scroll;
  align-items: flex-start;
  width: 100vw;
  margin: 0 calc(50% - 50vw);
}

.how2item {
  margin-right: 20px;
}

.how2item:last-child {
  margin-right: 0;
}

.how2txt {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.how2img {
  width: 250px;
  height: auto;
  margin: 0 auto 10px;
}

.custom-wrapper {
  position: relative;
  margin-bottom: -40px;
}

.splide__arrows {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* top: -290px; */
}

.splide__arrows:disabled {
  display: none;
}

.splide__arrow--prev {
  position: relative;
  text-align: center;
  background: #EDEBA0;
  width: 60px;
  height: 60px;
  border-radius: 60px;
  transform: scaleX(-1);
}

.splide__arrow--next {
  position: relative;
  text-align: center;
  background: #EDEBA0;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}

.splide__arrow--prev::after,
.splide__arrow--next::after {
  position: absolute;
  content: "";
  background-image: url(../../assets/arrow01.svg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 30px;
  height: 30px;
  top: calc(50% - 15px);
  left: calc(50% - 12px);
}

.splide__pagination{
  display: none;
}

.howto_arrow {
  top: -290px;
}