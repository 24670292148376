.flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper{
  padding: 50px 15px;
}

.bold{
  font-weight: bold;
}

.otherlink{
  text-decoration: underline;
  color: #1558d6;
}

.h3{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 30px;
}

.h4{
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 20px;
}

.img{
  display: block;
  width: 100%;
  /* line-height: 0; */
}

.container{
  width: 100%;
  margin: 0 auto;
  /* padding: 0 15px; */
  position: relative;
  font-family: "M PLUS 1", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  overflow: hidden;
}

.main-bg-color {
  background-color: #83CCD2;
}

.main-f-color {
  color: #83CCD2;
}

.back{
  background-image: url(./assets/back.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.finish_image{
  margin-bottom: 25px;
}

.Toastify__progress-bar-theme--light{
  background: #FF5A5F;
}

.Toastify__toast-body > div:last-child{
  color: #423E3B;
  font-family: "M PLUS 1", sans-serif;
}

@media screen and (min-width: 480px) {
  .container {
    width: 393px;
  }
}